import PropTypes from "prop-types"
import React from "react"

import { Link } from "gatsby"
import Logo from "../components/logo"
import ContentWrapper from './contentWrapper';

import stylesheet from "./header.module.css";

const Header = ({ siteTitle }) => (
  <ContentWrapper>
    <header className={stylesheet.header}>
      <Link to="/" alt={siteTitle} className={stylesheet.link}>
        <Logo />
      </Link>
    </header>
  </ContentWrapper>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
