import React from 'react';

import stylesheet from './contentWrapper.module.css';

const ContentWrapper = ({ children }) => {
  return (
    <div className={stylesheet.wrapper}>
      {children}
    </div>
  );
}

export default ContentWrapper;
