/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import cx from 'classnames';

import ContentWrapper from './contentWrapper';
import SubscribeForm from './subscribeForm.js'
import Header from './header';
import './global.css';
import stylesheet from './layout.module.css';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>
        <ContentWrapper>
          <div className={stylesheet.navigation}>
            {/* <Link className={stylesheet.navlink} to="/posts/">
              Posts
            </Link> */}
            <Link className={stylesheet.navlink} to="/about/">
              About
            </Link>
            <a
              className={cx(stylesheet.navlink, stylesheet.subscribeLink)}
              href="#subscribeForm"
            >
              Subscribe
            </a>
          </div>
        </ContentWrapper>
        <div className={stylesheet.layout}>{children}</div>
        <SubscribeForm />
      </main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
