import React from 'react';

const Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199 45" fill="none">
<path d="M3.0295 22.5C1.21428 23.5598 0 25.4885 0 27.6923C0 31.0381 2.79792 33.75 6.24942 33.75C8.04538 33.75 9.66342 33.0143 10.8033 31.8383V31.8399L19.3647 23.1672C19.5619 23.0082 19.6875 22.7696 19.6875 22.5021C19.6875 22.5014 19.6875 22.5007 19.6875 22.5H3.0295Z" fill="#A0277D"/>
<path d="M3.0295 22.5C1.21428 21.4402 0 19.5115 0 17.3077C0 13.9619 2.79792 11.25 6.24942 11.25C8.04538 11.25 9.66342 11.9857 10.8033 13.1617V13.1601L19.3647 21.8328C19.5619 21.9918 19.6875 22.2304 19.6875 22.4979C19.6875 22.4986 19.6875 22.4993 19.6875 22.5H3.0295Z" fill="#FF40C9"/>
<path d="M22.5 41.9705C21.4402 43.7857 19.5115 45 17.3077 45C13.9619 45 11.25 42.2021 11.25 38.7506C11.25 36.9546 11.9857 35.3366 13.1617 34.1967H13.1601L21.8328 25.6353C21.9918 25.4381 22.2304 25.3125 22.4979 25.3125C22.4986 25.3125 22.4993 25.3125 22.5 25.3125V41.9705Z" fill="#FF40C9"/>
<path d="M22.5 41.9705C23.5598 43.7857 25.4885 45 27.6923 45C31.0381 45 33.75 42.2021 33.75 38.7506C33.75 36.9546 33.0143 35.3366 31.8383 34.1967H31.8399L23.1672 25.6353C23.0082 25.4381 22.7696 25.3125 22.5021 25.3125C22.5014 25.3125 22.5007 25.3125 22.5 25.3125V41.9705Z" fill="#A0277D"/>
<path d="M41.9705 22.5C43.7857 23.5598 45 25.4885 45 27.6923C45 31.0381 42.2021 33.75 38.7506 33.75C36.9546 33.75 35.3366 33.0143 34.1967 31.8383V31.8399L25.6353 23.1672C25.4381 23.0082 25.3125 22.7696 25.3125 22.5021C25.3125 22.5014 25.3125 22.5007 25.3125 22.5H41.9705Z" fill="#FF40C9"/>
<path d="M41.9705 22.5C43.7857 21.4402 45 19.5115 45 17.3077C45 13.9619 42.2021 11.25 38.7506 11.25C36.9546 11.25 35.3366 11.9857 34.1967 13.1617V13.1601L25.6353 21.8328C25.4381 21.9918 25.3125 22.2304 25.3125 22.4979C25.3125 22.4986 25.3125 22.4993 25.3125 22.5H41.9705Z" fill="#A0277D"/>
<path d="M22.5 3.0295C23.5598 1.21428 25.4885 0 27.6923 0C31.0381 0 33.75 2.79792 33.75 6.24942C33.75 8.04538 33.0143 9.66342 31.8383 10.8033H31.8399L23.1672 19.3647C23.0082 19.5619 22.7696 19.6875 22.5021 19.6875C22.5014 19.6875 22.5007 19.6875 22.5 19.6875V3.0295Z" fill="#FF40C9"/>
<path d="M22.5 3.0295C21.4402 1.21428 19.5115 0 17.3077 0C13.9619 0 11.25 2.79792 11.25 6.24942C11.25 8.04538 11.9857 9.66342 13.1617 10.8033H13.1601L21.8328 19.3647C21.9918 19.5619 22.2304 19.6875 22.4979 19.6875C22.4986 19.6875 22.4993 19.6875 22.5 19.6875V3.0295Z" fill="#A0277D"/>
<path d="M67.2313 29.0319V32.7558H54V10.6105H58.6102V29.0319H67.2313Z" fill="#A0277D"/>
<path d="M75.9593 16.7764C77.1477 16.7764 78.2285 16.9646 79.2018 17.3411C80.1751 17.7175 81.01 18.2568 81.7067 18.9589C82.4033 19.6507 82.9412 20.4952 83.3203 21.4924C83.6993 22.4895 83.8889 23.6138 83.8889 24.8653C83.8889 26.1168 83.6993 27.2462 83.3203 28.2535C82.9412 29.2506 82.4033 30.1002 81.7067 30.8023C81.01 31.5043 80.1751 32.0436 79.2018 32.42C78.2285 32.7965 77.1477 32.9847 75.9593 32.9847C74.7606 32.9847 73.6695 32.7965 72.686 32.42C71.7128 32.0436 70.8778 31.5043 70.1811 30.8023C69.4845 30.1002 68.9415 29.2506 68.5522 28.2535C68.1731 27.2462 67.9836 26.1168 67.9836 24.8653C67.9836 23.6138 68.1731 22.4895 68.5522 21.4924C68.9415 20.4952 69.4845 19.6507 70.1811 18.9589C70.8778 18.2568 71.7128 17.7175 72.686 17.3411C73.6695 16.9646 74.7606 16.7764 75.9593 16.7764ZM75.9593 29.8255C77.1682 29.8255 78.0646 29.4134 78.6486 28.5893C79.2325 27.7549 79.5245 26.5187 79.5245 24.8806C79.5245 23.2526 79.2325 22.0265 78.6486 21.2024C78.0646 20.3681 77.1682 19.9509 75.9593 19.9509C74.7196 19.9509 73.8079 20.3681 73.2239 21.2024C72.6399 22.0265 72.3479 23.2526 72.3479 24.8806C72.3479 26.5187 72.6399 27.7549 73.2239 28.5893C73.8079 29.4134 74.7196 29.8255 75.9593 29.8255Z" fill="#A0277D"/>
<path d="M100.625 17.0206L94.4016 32.7558H90.5597L84.336 17.0206H87.8551C88.1624 17.0206 88.4186 17.0918 88.6235 17.2342C88.8284 17.3767 88.9718 17.5598 89.0537 17.7837L91.7123 25.5674C91.8865 26.0964 92.0401 26.6154 92.1733 27.1241C92.3065 27.6227 92.4243 28.1263 92.5268 28.635C92.6395 28.1263 92.7624 27.6227 92.8956 27.1241C93.039 26.6154 93.2029 26.0964 93.3874 25.5674L96.1381 17.7837C96.2098 17.5598 96.3481 17.3767 96.553 17.2342C96.7579 17.0918 96.9987 17.0206 97.2753 17.0206H100.625Z" fill="#A0277D"/>
<path d="M110.322 26.1778C109.288 26.2287 108.427 26.3203 107.741 26.4526C107.054 26.5747 106.506 26.7374 106.096 26.9409C105.697 27.1444 105.41 27.3785 105.236 27.643C105.072 27.8974 104.99 28.1772 104.99 28.4824C104.99 29.0929 105.159 29.5253 105.497 29.7797C105.845 30.0341 106.327 30.1612 106.942 30.1612C107.649 30.1612 108.258 30.0391 108.77 29.795C109.293 29.5406 109.81 29.1489 110.322 28.6198V26.1778ZM101.686 19.2031C102.618 18.3687 103.648 17.7481 104.775 17.3411C105.902 16.9239 107.121 16.7153 108.432 16.7153C109.375 16.7153 110.215 16.868 110.953 17.1732C111.7 17.4784 112.33 17.9058 112.843 18.4552C113.365 18.9945 113.765 19.6406 114.041 20.3935C114.318 21.1464 114.456 21.9706 114.456 22.866V32.7558H112.52C112.12 32.7558 111.813 32.6998 111.598 32.5879C111.393 32.476 111.224 32.242 111.091 31.8859L110.753 30.8786C110.353 31.2245 109.964 31.5297 109.585 31.7943C109.216 32.0588 108.832 32.2827 108.432 32.4658C108.033 32.6388 107.602 32.7711 107.141 32.8626C106.691 32.9542 106.184 33 105.62 33C104.923 33 104.288 32.9084 103.714 32.7253C103.141 32.5421 102.644 32.2725 102.224 31.9164C101.814 31.5501 101.496 31.0973 101.271 30.5581C101.046 30.0188 100.933 29.3981 100.933 28.6961C100.933 28.1161 101.082 27.5362 101.379 26.9562C101.676 26.3762 102.183 25.8522 102.9 25.3842C103.627 24.906 104.59 24.5143 105.789 24.209C106.998 23.8936 108.509 23.7155 110.322 23.6749V22.866C110.322 21.879 110.112 21.1566 109.692 20.6987C109.272 20.2307 108.668 19.9967 107.879 19.9967C107.295 19.9967 106.808 20.0679 106.419 20.2104C106.03 20.3426 105.687 20.4902 105.39 20.653C105.103 20.8056 104.831 20.9531 104.575 21.0956C104.319 21.2278 104.022 21.294 103.684 21.294C103.387 21.294 103.136 21.2227 102.931 21.0803C102.736 20.9277 102.572 20.7496 102.439 20.5461L101.686 19.2031Z" fill="#A0277D"/>
<path d="M122.008 28.6045C122.408 29.0624 122.838 29.3829 123.299 29.566C123.771 29.7492 124.257 29.8407 124.759 29.8407C125.282 29.8407 125.753 29.7492 126.173 29.566C126.603 29.3727 126.972 29.0726 127.279 28.6656C127.587 28.2484 127.822 27.7091 127.986 27.0478C128.15 26.3864 128.232 25.5877 128.232 24.6516C128.232 23.8173 128.166 23.1102 128.032 22.5302C127.899 21.9401 127.699 21.4568 127.433 21.0803C127.177 20.7038 126.859 20.4291 126.48 20.2561C126.101 20.0832 125.661 19.9967 125.159 19.9967C124.493 19.9967 123.914 20.1442 123.422 20.4393C122.941 20.7242 122.469 21.1464 122.008 21.706V28.6045ZM122.008 18.8368C122.633 18.2161 123.325 17.7175 124.083 17.3411C124.841 16.9646 125.691 16.7764 126.634 16.7764C127.546 16.7764 128.365 16.9595 129.093 17.3258C129.83 17.6819 130.455 18.1958 130.968 18.8673C131.49 19.5388 131.89 20.3528 132.166 21.3092C132.453 22.2555 132.597 23.3187 132.597 24.499C132.597 25.7708 132.433 26.9308 132.105 27.9788C131.787 29.0166 131.331 29.9069 130.737 30.6496C130.143 31.3924 129.431 31.9673 128.601 32.3743C127.781 32.7812 126.864 32.9847 125.85 32.9847C125.358 32.9847 124.918 32.9339 124.529 32.8321C124.139 32.7405 123.781 32.6083 123.453 32.4353C123.125 32.2623 122.823 32.0588 122.546 31.8248C122.27 31.5806 122.008 31.311 121.763 31.0159L121.593 31.9622C121.522 32.2572 121.404 32.4658 121.24 32.5879C121.076 32.6998 120.856 32.7558 120.579 32.7558H117.767V10H122.008V18.8368Z" fill="#A0277D"/>
<path d="M139.598 10V32.7558H135.356V10H139.598Z" fill="#A0277D"/>
<path d="M153.179 23.0338C153.179 22.5963 153.118 22.1792 152.995 21.7823C152.882 21.3855 152.698 21.0345 152.442 20.7293C152.186 20.424 151.863 20.1849 151.474 20.0119C151.084 19.8288 150.618 19.7372 150.075 19.7372C149.061 19.7372 148.267 20.0221 147.693 20.5919C147.12 21.1617 146.746 21.9757 146.571 23.0338H153.179ZM146.51 25.5368C146.643 27.0122 147.063 28.0907 147.77 28.7724C148.487 29.4541 149.42 29.795 150.567 29.795C151.151 29.795 151.653 29.7288 152.073 29.5965C152.503 29.4541 152.877 29.3015 153.195 29.1387C153.523 28.9657 153.815 28.8131 154.071 28.6808C154.337 28.5384 154.598 28.4672 154.855 28.4672C155.182 28.4672 155.438 28.5893 155.623 28.8334L156.852 30.3597C156.402 30.8786 155.905 31.311 155.362 31.6569C154.819 31.9927 154.255 32.2623 153.671 32.4658C153.087 32.6591 152.498 32.7914 151.904 32.8626C151.31 32.944 150.736 32.9847 150.183 32.9847C149.076 32.9847 148.042 32.8067 147.079 32.4506C146.126 32.0843 145.291 31.5501 144.574 30.848C143.867 30.1358 143.308 29.2557 142.899 28.2077C142.489 27.1597 142.284 25.9438 142.284 24.5601C142.284 23.4917 142.458 22.4844 142.806 21.5382C143.165 20.5919 143.677 19.7678 144.343 19.0657C145.009 18.3636 145.818 17.8091 146.771 17.4021C147.724 16.985 148.8 16.7764 149.998 16.7764C151.013 16.7764 151.945 16.9392 152.795 17.2648C153.656 17.5802 154.394 18.0431 155.008 18.6536C155.633 19.2641 156.115 20.017 156.453 20.9124C156.801 21.7976 156.975 22.81 156.975 23.9496C156.975 24.265 156.96 24.5244 156.929 24.7279C156.898 24.9314 156.847 25.0942 156.775 25.2163C156.704 25.3384 156.606 25.4249 156.483 25.4758C156.361 25.5165 156.202 25.5368 156.007 25.5368H146.51Z" fill="#A0277D"/>
<path d="M175.344 27.3072C175.6 27.3072 175.815 27.3988 175.989 27.582L177.818 29.5202C176.917 30.6598 175.795 31.5247 174.453 32.1148C173.121 32.7049 171.533 33 169.689 33C168.019 33 166.518 32.7202 165.186 32.1606C163.854 31.5908 162.722 30.8023 161.79 29.795C160.858 28.7877 160.141 27.5921 159.639 26.2084C159.137 24.8246 158.886 23.3136 158.886 21.6755C158.886 20.5766 159.003 19.5337 159.239 18.5468C159.485 17.5497 159.838 16.6339 160.299 15.7996C160.76 14.9653 161.314 14.2123 161.959 13.5408C162.615 12.8693 163.352 12.2995 164.172 11.8315C164.992 11.3532 165.883 10.992 166.846 10.7478C167.819 10.4935 168.854 10.3663 169.95 10.3663C170.77 10.3663 171.543 10.4375 172.271 10.58C173.008 10.7224 173.695 10.9208 174.33 11.1752C174.965 11.4296 175.549 11.7399 176.082 12.1062C176.625 12.4623 177.111 12.854 177.542 13.2814L175.989 15.3875C175.897 15.5198 175.779 15.6368 175.636 15.7386C175.493 15.8403 175.298 15.8912 175.052 15.8912C174.806 15.8912 174.555 15.8047 174.299 15.6317C174.053 15.4587 173.746 15.2654 173.377 15.0518C173.018 14.8381 172.563 14.6448 172.009 14.4718C171.466 14.2988 170.775 14.2123 169.935 14.2123C169.002 14.2123 168.147 14.3802 167.368 14.716C166.59 15.0518 165.919 15.5401 165.355 16.1812C164.802 16.812 164.372 17.5904 164.064 18.5163C163.757 19.4422 163.603 20.4952 163.603 21.6755C163.603 22.866 163.767 23.9292 164.095 24.8653C164.433 25.7912 164.889 26.5747 165.463 27.2157C166.047 27.8567 166.723 28.3451 167.491 28.6808C168.27 29.0064 169.1 29.1692 169.981 29.1692C170.503 29.1692 170.975 29.1438 171.395 29.0929C171.825 29.042 172.219 28.9555 172.578 28.8334C172.947 28.7113 173.29 28.5536 173.608 28.3603C173.935 28.167 174.263 27.9228 174.591 27.6277C174.704 27.5362 174.822 27.4599 174.945 27.3988C175.067 27.3378 175.201 27.3072 175.344 27.3072Z" fill="#A0277D"/>
<path d="M199 32.7558H194.39C194.082 32.7558 193.831 32.6795 193.637 32.5269C193.452 32.3743 193.304 32.1962 193.191 31.9927L188.366 23.8427C188.315 23.9547 188.263 24.0666 188.212 24.1785C188.171 24.2802 188.12 24.3769 188.058 24.4685L183.479 31.9927C183.346 32.186 183.187 32.3641 183.003 32.5269C182.828 32.6795 182.603 32.7558 182.326 32.7558H178.024L185.323 21.355L178.3 10.6105H182.91C183.218 10.6105 183.443 10.6512 183.587 10.7326C183.73 10.814 183.858 10.9463 183.971 11.1294L188.765 18.9589C188.817 18.8368 188.873 18.7147 188.934 18.5926C188.996 18.4603 189.068 18.328 189.15 18.1958L193.452 11.2057C193.575 11.0022 193.708 10.8547 193.852 10.7631C194.006 10.6614 194.19 10.6105 194.405 10.6105H198.816L191.731 21.1566L199 32.7558Z" fill="#A0277D"/>
</svg>
  );
};

export default Logo;
