import React, { useState } from 'react';

import addToMailchimp from 'gatsby-plugin-mailchimp';

import ContentWrapper from './contentWrapper';

import stylesheet from './subscribeForm.module.css';
import successImage from '../images/check-your-mail-charlie-brown.jpg';

const SubscribeForm = () => {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [mcResult, setMCResult] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Mailchimp always responds with status code 200, accompanied by a string indicating the result of the response.
    const { result, msg } = await addToMailchimp(email, { FNAME: firstName });

    setMCResult(result);

    if (result === 'success') {
      setFirstName('');
      setEmail('');
    }

    // Removes the HTML returned in some response messages in case of error
    setMessage(msg.split('<')[0]);
    setStatus(result);
  };

  const handleFirstNameChange = (event) => setFirstName(event.target.value);

  const handleEmailChange = (event) => setEmail(event.target.value);

  return (
    <ContentWrapper>
      <div className={stylesheet.mailchimpForm} id="subscribeForm">
        {(mcResult === 'success') ? (
          <div>
            <h3 className={stylesheet.mailchimpFormHeader}>Yay! Now go check <br/>your email.</h3>
              <img src={successImage} className={stylesheet.successImage} alt="Charlie Brown checking his mailbox."/>
              <p className={stylesheet.optInMessage}>This mailing list uses double opt-in. Please check your inbox and confirm your subscription. </p>
          </div>
        ) : (
          <div>
            <h3 className={stylesheet.mailchimpFormHeader}>Send your inbox some love.</h3>
            <div className={stylesheet.formWrapper}>
              <form onSubmit={handleSubmit}>
                <fieldset className={stylesheet.fieldset}>
                  <label for="firstName">Name <span className={stylesheet.opt}>(optional)</span></label>
                  <input
                    id="firstName"
                    type="text"
                    onChange={handleFirstNameChange}
                    value={firstName}
                    placeholder="Charlie Brown"
                  />
                </fieldset>
                <fieldset className={stylesheet.fieldset}>
                  <label for="email">Email</label>
                  <input
                    id="email"
                    type="email"
                    onChange={handleEmailChange}
                    value={email}
                    placeholder="charlie@peanuts.com"
                    required
                  />
                  <span
                    status={status}
                    className={
                      status === 'success'
                        ? stylesheet.messageSuccess
                        : stylesheet.messageFailure
                    }
                  >
                    {message}
                  </span>
                </fieldset>
                <button type="submit" className={stylesheet.submit}>
                  Sign Up
                </button>
                <p className={stylesheet.consentMessage}>By clicking “sign up” you agree to receive emails from LovableCX. You may unsubscribe at any time.</p>
              </form>
            </div>
          </div>
        )}
      </div>
    </ContentWrapper>
  );
};

export default SubscribeForm;
